import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { deleteApi, getApi, postApi, putApi } from "../utils/fetchUtils";
import { CallbackType } from "../utils/types";
import { AppThunk } from "../store";

const initialState: any = {
  brand: { isLoading: true, data: [], isError: false },
  user: { isLoading: true, data: [], isError: false },
  cameraAnimation: { isLoading: true, data: [], isError: false },
  productAnimation: { isLoading: true, data: [], isError: false },
  product: { isLoading: true, data: [], isError: false },
  productCategory: { isLoading: true, data: [], isError: false },
  map: { isLoading: true, data: [], isError: false },
  tag: { isLoading: true, data: [], isError: false },
};

const assetFormSlice = createSlice({
  name: "assetForm",
  initialState,
  reducers: {
    setAssetLoading: (state, action: PayloadAction<any>) => {
      state[action.payload.assetType] = {
        data: [...state[action.payload.assetType].data],
        isLoading: true,
        isError: false,
      };
    },
    setAssetError: (state, action: PayloadAction<any>) => {
      state[action.payload.assetType] = {
        data: [],
        isLoading: false,
        isError: true,
      };
    },
    updateAssets: (state, action: PayloadAction<any>) => {
      state[action.payload.assetType] = {
        data: action.payload?.assets?.sort((a: any, b: any) =>
          a?.created_at > b?.created_at ? -1 : 1
        ),
        isLoading: false,
        isError: false,
      };
    },
    updateBrands: (state, action: PayloadAction<any>) => {
      state.brand = { data: action.payload, isLoading: false, isError: false };
    },
    updateProducts: (state, action: PayloadAction<any>) => {
      state.product = {
        data: action.payload,
        isLoading: false,
        isError: false,
      };
    },
    updateUsers: (state, action: PayloadAction<any>) => {
      state.user = { data: action.payload, isLoading: false, isError: false };
    },
    updateMaps: (state, action: PayloadAction<any>) => {
      state.map = { data: action.payload, isLoading: false, isError: false };
    },
    updateTags: (state, action: PayloadAction<any>) => {
      state.tag = { data: action.payload, isLoading: false, isError: false };
    },
    updateProductAnimations: (state, action: PayloadAction<any>) => {
      state.productAnimation = {
        data: action.payload,
        isLoading: false,
        isError: false,
      };
    },
    updateCameraAnimations: (state, action: PayloadAction<any>) => {
      state.cameraAnimation = {
        data: action.payload,
        isLoading: false,
        isError: false,
      };
    },
    updateProductCategories: (state, action: PayloadAction<any>) => {
      state.productCategory = {
        data: action.payload,
        isLoading: false,
        isError: false,
      };
    },
    deleteStateById: (state, action: PayloadAction<any>) => {
      state[action.payload.assetType] = {
        data: state[action.payload.assetType].data.filter((item: any) => item?.id !== action.payload?.id),
        isLoading: true,
        isError: false,
      };
    },
    addAssetById: (state, action: PayloadAction<any>) => {
      state[action.payload.assetType] = {
        ...state[action.payload.assetType],
        data: [...state[action.payload.assetType].data, action.payload.item],  // Properly update the array
        isLoading: true,
        isError: false,
      };
    },
    updateAssetById: (state, action: PayloadAction<any>) => {
      const { assetType, item } = action.payload;
      const assetIndex = state[assetType].data.findIndex((asset: any) => asset.id === item.id);

      if (assetIndex !== -1) {
        // If the asset is found, update it
        state[assetType].data[assetIndex] = { ...state[assetType].data[assetIndex], ...item };
      }

      state[assetType] = {
        ...state[assetType],
        isLoading: true,
        isError: false,
      };
    },
  },
});

export default assetFormSlice.reducer;

export const deleteAssetsById = (id: string, assetType: string): AppThunk =>
  async (dispatch) => {
    dispatch(assetFormSlice.actions.deleteStateById({ id, assetType }));
  };

export const getAssets =
  (assetType: any, assetUrl: any, callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      dispatch(assetFormSlice.actions.setAssetLoading({ assetType }));
      const assets = await getApi(`${assetUrl}`);
      dispatch(assetFormSlice.actions.updateAssets({ assets, assetType }));
      callback?.();
    };
export const getProducts =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const products = await getApi("products/?pagination=false");
      dispatch(
        assetFormSlice.actions.updateProducts(
          products?.sort((a: any, b: any) =>
            a?.created_at > b?.created_at ? -1 : 1
          )
        )
      );
      callback?.();
    };
export const getBrands =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const response = await getApi("brands/");
      const brands = response && response?.results ? response?.results : [];
      dispatch(
        assetFormSlice.actions.updateBrands(
          brands?.sort((a: any, b: any) =>
            a?.created_at > b?.created_at ? -1 : 1
          )
        )
      );

      callback?.();
    };
export const getMaps =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const response = await getApi("maps/");
      const maps = response && response?.results ? response?.results : [];
      dispatch(
        assetFormSlice.actions.updateMaps(
          maps?.sort((a: any, b: any) => (a?.created_at > b?.created_at ? -1 : 1))
        )
      );

      callback?.();
    };
export const getTags =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const tags = await getApi("tags/?pagination=false");
      dispatch(
        assetFormSlice.actions.updateTags(
          tags?.sort((a: any, b: any) => (a?.created_at > b?.created_at ? -1 : 1))
        )
      );

      callback?.();
    };
export const getUsers =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const response = await getApi("users/");
      const users = response && response?.results ? response?.results : [];
      dispatch(
        assetFormSlice.actions.updateUsers(
          users?.sort((a: any, b: any) =>
            a?.created_at > b?.created_at ? -1 : 1
          )
        )
      );
      callback?.();
    };
export const getProductAnimations =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const response = await getApi("productAnimations/");
      const productAnimation = response && response?.results ? response?.results : [];
      dispatch(
        assetFormSlice.actions.updateProductAnimations(
          productAnimation?.sort((a: any, b: any) =>
            a?.created_at > b?.created_at ? -1 : 1
          )
        )
      );
      callback?.();
    };
export const getCameraAnimations =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const response = await getApi("cameraAnimations/");
      const cameraAnimation = response && response?.results ? response?.results : [];

      dispatch(
        assetFormSlice.actions.updateCameraAnimations(
          cameraAnimation?.sort((a: any, b: any) =>
            a?.created_at > b?.created_at ? -1 : 1
          )
        )
      );
      callback?.();
    };
export const getProductCategories =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const productCategories = await getApi("productCategories/?pagination=false");
      dispatch(
        assetFormSlice.actions.updateProductCategories(
          productCategories?.sort((a: any, b: any) =>
            a?.created_at > b?.created_at ? -1 : 1
          )
        )
      );
      callback?.();
    };

export const uploadAssetS3 =
  (payload: { file: Blob; callback?: any }): AppThunk =>
    async (dispatch) => {
      const formData = new FormData();
      formData.append("file", payload.file);
      const data = await postApi("upload/", formData);
      payload?.callback?.(data);
    };

export const createAsset =
  (assetTypeUrl: string, payload: any, assetType: any, callback?: any): AppThunk =>
    async (dispatch) => {
      try {
        const response = await postApi(assetTypeUrl, payload);
        response && dispatch(assetFormSlice.actions.addAssetById({ item: response, assetType }))
        callback?.(false);
      } catch {
        callback?.(true);
      }
    };
export const editAsset =
  (assetTypeUrl: string, payload: any, assetType: any, callback?: any): AppThunk =>
    async (dispatch) => {
      try {
        const response = await putApi(`${assetTypeUrl}/`, payload);
        response && dispatch(assetFormSlice.actions.updateAssetById({ item: response, assetType }))
        callback?.(false);
      } catch {
        callback?.(true);
      }
    };

export const deleteAsset =
  (assetTypeUrl: string, assetId: string, callback?: any): AppThunk =>
    async (dispatch) => {
      try {
        await deleteApi(`${assetTypeUrl}` + `${assetId}/`);
        callback?.(false);
      } catch {
        callback?.(true);
      }
    };
