import React, { useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getApiPaginated, getMaps } from "utils/fetchUtils";


const GlobalAssetGrid = ({
  onClick,
  styles,
  path,
  lanes,
  type
}: {
  path: string
  onClick: (asset: any) => void;
  styles: any;
  lanes?: number;
  type?: string
}) => {
  // console.log("type", type)
  // const [debounceSearch, setDebounceSearch] = useState(searchStr);
  const {
    status,
    data,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,

  } = useInfiniteQuery<any, any>({
    queryKey: [`/${path}`],
    queryFn: ({ pageParam }: any) =>
      getApiPaginated(getMaps({ path, page: pageParam, rowsPerPage: 10 })),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam: any) => {
      if (lastPage.next) {
        return lastPageParam + 1;
      } else {
        return undefined
      }
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam: any) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    refetchOnWindowFocus: false,
  });

  const allAssets = data ? data.pages.flatMap((d) => d.results) : [];

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: allAssets.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
    overscan: 3,
    lanes: lanes ?? 3,
  });

  React.useEffect(() => {
    const [lastItem] = [...virtualizer.getVirtualItems()].reverse();
    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= allAssets.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allAssets.length,
    isFetchingNextPage,
    virtualizer.getVirtualItems(),
  ]);

  const items = virtualizer.getVirtualItems();
  return (
    <>
      {status === "pending" ? (
        <h5 style={{ color: "#7D899C", textAlign: "center", width: "100%" }}>
          Loading...
        </h5>
      ) : status === "error" ? (
        <span>Error: {(error as Error).message}</span>
      ) : (
        <div
          ref={parentRef}
          className="List"
          style={{
            height: `100%`,
            width: `100%`,
            overflow: "auto",
            paddingBottom: '50px'
          }}
        >
          <div
            style={{
              height: `${virtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
              }}
            >
              {items.map((virtualRow) => {
                var asset = allAssets[virtualRow.index];
                return (
                  <div
                    key={virtualRow.index}
                    data-index={virtualRow.index}
                    ref={virtualizer.measureElement}
                    className={
                      virtualRow.index % 2 ? "ListItemOdd" : "ListItemEven"
                    }
                    style={{
                      position: "absolute",
                      top: 0,
                      left: `${virtualRow.lane * (100 / (lanes || 3))}%`,
                      width: `${(100 / (lanes || 3))}%`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    <div
                      key={asset.id}
                      className={styles.asset_div}
                      onClick={() => onClick(asset)}
                    >
                      <div
                        className={`${styles.imageContainer}`}
                        style={{
                          aspectRatio: 1,
                          width: "100%",
                          height:'unset',
                          padding:'0px'
                        }}
                      >
                        {type === "video" ?
                          <video
                            title={asset.name}
                            src={asset.thumbnail}
                            autoPlay
                            loop
                            className={`${styles.img}`}
                            muted
                            playsInline
                          /> : <img
                            src={asset.thumbnail}
                            title={asset.name}
                            alt={asset.name}
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "0.5rem",
                            }}
                          />}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default (GlobalAssetGrid);
