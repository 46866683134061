import { Grid } from '@mui/material';
import React from 'react'
import { TypographyBodyLarge, TypographyTitleGroup } from '../../../../components/StyledComponets/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { useAppSelector } from '../../../../hooks/store';
import { ProjectCategory } from '../../../../utils/types';

type SelectCategoryProps = { selectCategory: ProjectCategory | { name: "" }, setSelectedCategory: Function };

const SelectCategory = ({ selectCategory, setSelectedCategory }: SelectCategoryProps) => {

    const projectCategories = useAppSelector(store => store.project.projectCategories)
    return (
        <Grid
            display={"flex"}
            width={"100%"}
            gap={1.5}
            flexDirection={"column"}
        >
            <TypographyBodyLarge sx={{ fontSize: '14px !important' }} >Category *</TypographyBodyLarge>
            <div style={{ display: 'flex', alignItems: 'center', gap: "16px" ,flexWrap:'wrap'}} >
                {projectCategories?.map((option) => {
                    const option_value = option as ProjectCategory
                    return <button
                        key={option_value.name}
                        style={{
                            padding: '0.5rem 1rem',
                            backgroundColor: "#253042",
                            borderRadius: '10px',
                            border: selectCategory.name === option_value.name ? '2px solid white' : '2px solid #394455',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            justifyContent: 'center',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            color: '#FFFFFF',
                            cursor: 'pointer'

                        }}
                        onClick={() => setSelectedCategory(option_value)}
                    >
                        <TypographyTitleGroup style={{ color: '#7D899C' }}>{option_value.name}</TypographyTitleGroup>

                        <div style={{ height: '20px', width: '20px', borderRadius: '50%', backgroundColor: '#1A202B', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            {
                                selectCategory && selectCategory.name === option_value.name ? <CheckIcon sx={{ fontSize: '1rem', color: '#7D899C' }} /> : <></>
                            }
                        </div>

                    </button>
                })}
            </div>

        </Grid>

    )

}

export default React.memo(SelectCategory);
