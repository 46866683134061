import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import React from "react";
import styles from "./CreateVideoModal.module.css";
import DialogSelectField from "../DialogSelectField";
import { TextFieldStyled } from "../../../../components/StyledComponets/Input";
import {
    TypographyBodyLarge, TypographyCTA1,
} from "../../../../components/StyledComponets/Typography";
import { Folder, ProjectCategory } from "../../../../utils/types";
import { useAppSelector } from "../../../../hooks/store";
import { PrimaryButton } from "../../../../components/StyledComponets/Button";
import SelectCategory from "../SelectCategory";

import SelectRation from "../SelectRation";
import ClearIcon from '@mui/icons-material/Clear';


type NewProjectStepProps = {
    onClose: React.MouseEventHandler<HTMLDivElement>;
    onSelectScene: React.MouseEventHandler<HTMLButtonElement>;
    projectName: string;
    setProjectName: Function;
    selectCategory: ProjectCategory | { name: "" };
    setSelectCategory: Function;
    selectDuration: { label: string, value: number | null };
    setSelectDuration: Function;
    selectRatio: { label: string, value: number, size: string };
    setSelectRatio: Function;
    folder: string;
    setFolder: Function;
};
const NewProjectStep = ({ onClose, folder, setFolder, projectName, setProjectName, selectCategory, setSelectCategory, selectDuration, setSelectDuration, selectRatio, setSelectRatio, onSelectScene }: NewProjectStepProps) => {



    return (
        <>
            <DialogTitle className={styles.titleDiv}>
                <Grid
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"space-between"}
                    gap={4}
                    width={"100%"}
                >
                    <TypographyBodyLarge
                        sx={{
                            color: "var(--color-palette-white, #FFF)",
                            alignSelf: "center",
                        }}
                    >
                        {"New Project"}
                    </TypographyBodyLarge>
                    <div onClick={onClose} className={styles.iconDiv} >
                        <ClearIcon sx={{ color: 'white' }} />
                    </div>
                </Grid>

                <div className={styles.fieldDiv}>
                    <TextFieldStyled
                        placeholder="Project Name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                    <DialogSelectField
                      
                        folder={folder}
                        setFolder={setFolder}
                    />
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={styles.contentDiv}>
                    <SelectCategory selectCategory={selectCategory} setSelectedCategory={setSelectCategory} />
                    {/* <SelectDuration selectDuration={selectDuration} setSelectDuration={setSelectDuration} /> */}
                    <SelectRation selectRatio={selectRatio} setSelectRatio={setSelectRatio} />
                   

                </div>
            </DialogContent>
            <DialogActions>
                <div className={styles.lastDiv}>
                    <Typography className={styles.selectedOption}>
                        {/* {selectCategory.name ? selectCategory.name : ""}  */}
                        {/* {selectDuration.label ? <span className={styles.selectedOptionRes}>{`(${selectDuration.label}), `}</span> : ""} */}
                        {/* {selectRatio.label ? selectRatio.label : ""} */}
                    </Typography>
                    <PrimaryButton sx={{ p: "16px 24px" }} onClick={onSelectScene}>
                        <TypographyCTA1>Select Scene</TypographyCTA1>
                    </PrimaryButton>

                </div>
            </DialogActions>
        </>
    );
};

export default React.memo(NewProjectStep);
