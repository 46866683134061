
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { getApi } from '../utils/fetchUtils';
import { CallbackType, Product, CameraAnimationAssets, ProductAnimationAssets, } from '../utils/types';
import { AppThunk } from '../store';

const initialState = {
    products: [] as Product[],
    productAnimationAssets: null as ProductAnimationAssets[] | null,
    cameraAssets: null as CameraAnimationAssets[] | null,
    cameraAssetsLoading: false,
}
const productSlice = createSlice({
    name: "asset",
    initialState,
    reducers: {
        updateProductAssets: (state, action: PayloadAction<Product[]>) => {
            state.products = action.payload;
        },
        updateCameraAssets: (state, action: PayloadAction<CameraAnimationAssets[]>) => {
            state.cameraAssets = action.payload;
        },
        updateCameraLoading: (state, action: PayloadAction<boolean>) => {
            state.cameraAssetsLoading = action.payload;
        },
        updateProductAnimationAssets: (state, action: PayloadAction<ProductAnimationAssets[]>) => {
            state.productAnimationAssets = action.payload;
        },
    }
});

export default productSlice.reducer;
export const getAllProduct = (callback?: CallbackType): AppThunk =>
    async dispatch => {
        const allProducts: Product[] = await getApi("products/?pagination=false");
        dispatch(productSlice.actions.updateProductAssets(allProducts));
        callback?.();
    }

const isGuid = (id: string): boolean => {
    const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guidPattern.test(id);
}

export const getCameraAssets = (productId: string = "", productAnimationId: string = "", callback?: CallbackType): AppThunk =>
    async dispatch => {
        const validProductId = isGuid(productId) ? productId : "";
        const validProductAnimationId = isGuid(productAnimationId) ? productAnimationId : "";
        dispatch(productSlice.actions.updateCameraLoading(true));
        const cameraAnimationAssets: CameraAnimationAssets[] = await getApi(`cameraAnimations/?product=${validProductId}&productAnimationId=${validProductAnimationId}`);
        dispatch(productSlice.actions.updateCameraAssets(cameraAnimationAssets));
        dispatch(productSlice.actions.updateCameraLoading(false));
        callback?.();
    }
export const getProductAnimationAssets = (productId: string = "", cameraAnimationId: string = "", callback?: CallbackType): AppThunk =>
    async dispatch => {
        const productAnimationAssets: ProductAnimationAssets[] = await getApi(`productAnimations/?product=${productId}&cameraAnimationId=${cameraAnimationId}`);
        dispatch(productSlice.actions.updateProductAnimationAssets(productAnimationAssets));
        callback?.();
    }