import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import SnackbarProvider from './components/Snackbar';
import { Provider } from 'react-redux';
import { store } from './store';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  // <React.StrictMode>
  <Provider store={store} >
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>

        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </QueryClientProvider>


  </Provider>


  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
