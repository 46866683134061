import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { deleteApi, getApi, postApi } from "../utils/fetchUtils";
import {
  CallbackModelType,
  CallbackType,
  Folder,
  showSnackbarType,
} from "../utils/types";
import { SEVERITY } from "../utils/enums";
import { AppThunk } from "../store";
import { getAllProjects } from "./project";

const initialState = {
  allFolders: null as { [id: string]: Folder } | null,
  folders: [] as any,
};

const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    updateAllFolders: (state, action: PayloadAction<Folder[]>) => {
      state.allFolders = {};
      state.folders = action.payload;
      action.payload.forEach((folder) => {
        if (!state.allFolders) {
          state.allFolders = {};
        }
        state.allFolders[folder.id] = folder;
      });
    },
   
  },
});

export default folderSlice.reducer;

export const getAllFolders =
  (callback?: CallbackType): AppThunk =>
    async (dispatch) => {
      const response = await getApi("folders/");
      const allFolders: Folder[] = response && response?.results ? response?.results : [];
      dispatch(folderSlice.actions.updateAllFolders(allFolders));
      callback?.();
    };

export const createFolder =
  (
    payload: any,
    showSnackbar?: showSnackbarType,
    callback?: CallbackModelType<Folder>
  ): AppThunk =>
    async (dispatch) => {
      try {
        const folder: Folder = await postApi("folders/", payload);
        showSnackbar?.("Folder created.", SEVERITY.SUCCESS);
        if (showSnackbar) {
          dispatch(getAllFolders(() => callback?.(folder)));
        } else {
          callback?.(folder);
        }
      } catch {
        showSnackbar?.("Something went wrong.", SEVERITY.ERROR);
      }
    };

export const updateFolderById =
  (id: string, name: string, showSnackbar?: showSnackbarType): AppThunk =>
    async (dispatch) => {
      await postApi(`folder/${id}`, { name: name.trim() });
      showSnackbar?.("Folder updated.", SEVERITY.SUCCESS);
      if (showSnackbar) {
        dispatch(getAllFolders());
      }
    };

export const deleteFolderById =
  (
    id: string,
    showSnackbar?: showSnackbarType,
    callback?: CallbackType
  ): AppThunk =>
    async (dispatch) => {
      await deleteApi(`folders/${id}/`);
      showSnackbar?.("Folder deleted.", SEVERITY.SUCCESS);
      if (showSnackbar) {
        dispatch(getAllFolders(callback));
        dispatch(getAllProjects());
      } else {
        callback?.();
      }
    };
