import axios, { AxiosError, AxiosResponse } from "axios";
import { ASSET_TYPE, ASSETS_TABLE_OPTIONS, ASSETS_TABLE_OPTIONS_URL, SCOPE, TOKEN_SOURCE } from "./enums";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const API_KEY = process.env.REACT_APP_BACKEND_API_KEY;



function successHandler(response: AxiosResponse) {
  if (response?.data) {
    return response.data;
  } else {
    console.error("API error", response);
  }
}

function errorHandler(err: AxiosError | any) {
  if (err.response?.status === 401) {
    const tokenSource = window.localStorage.getItem("token_source");
    if (tokenSource && tokenSource === TOKEN_SOURCE.SESSION) {
      window.location.assign("/auth/expired");
    } else {
      window.location.assign("/auth/logout");
    }
  } else {
    console.error("API error", err.response?.data);
    throw (
      err.response?.data?.error ?? err.response?.data ?? err.response ?? err
    );
  }
}

function getHeaders(payload?: any, isPublic: boolean = false) {
  const headers: any = payload
    ? payload instanceof FormData
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/json" }
    : {};

  const token = window.localStorage.getItem("token");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const brandId = window.localStorage.getItem("brand_id");
  if (brandId) {
    headers["x-brand-id"] = brandId;
  }
  if (isPublic) {
    headers["x-api-key"] = API_KEY;
  }

  return headers;
}

export const createBackendUrl = (url: string) => `${BASE_URL}api/${url}`;

export function getApi(url: string, isPublic?: boolean) {
  return axios
    .get(createBackendUrl(url), { headers: getHeaders(null, isPublic) })
    .then(successHandler)
    .catch(errorHandler);
}

export function getApiWithoutCatch(url: string) {
  return axios
    .get(createBackendUrl(url), { headers: getHeaders() })
    .then(successHandler);
}

export function postApi(
  url: string,
  payload: any = {},
  isPublic: boolean = false
) {
  return axios
    .post(createBackendUrl(url), payload, {
      headers: getHeaders(payload, isPublic),
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function postFormApi(
  url: string,
  payload: any = {},
  isPublic: boolean = false
) {
  const formData = new FormData();
  for (const key in payload) {
    formData.append(key, payload[key]);
  }

  return axios
    .post(createBackendUrl(url), formData, {
      headers: getHeaders(formData, isPublic),
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function putApi(url: string, payload: any) {
  return axios
    .put(createBackendUrl(url), payload, { headers: getHeaders(payload) })
    .then(successHandler)
    .catch(errorHandler);
}

export function patchApi(url: string, payload: any) {
  return axios
    .patch(createBackendUrl(url), payload, { headers: getHeaders(payload) })
    .then(successHandler)
    .catch(errorHandler);
}
export function deleteApi(url: string) {
  return axios
    .delete(createBackendUrl(url), { headers: getHeaders() })
    .then(successHandler)
    .catch(errorHandler);
}
function successHandlerNew(response: AxiosResponse) {
  const json = response.data;
  if (json.success) {
    return json;
  } else if (response.status === 200) {
    return response.data;
  } else {
    console.error("API error", json);
  }
}
export function deleteApiNew(url: string, params?: any) {
  return axios
    .delete(createBackendUrl(url), { headers: getHeaders(), params })
    .then(successHandlerNew)
    .catch(errorHandler);
}
export function getApiPaginated(url: string, params?: any) {
  return axios
    .get(createBackendUrl(url), { headers: getHeaders(), params })
    .then(successHandlerNew)
    .catch(errorHandler);
}
export const getAssets = ({ page, rowsPerPage, path }: { page?: number; rowsPerPage?: number; path: string }) => {
  let url = "";
  if (page !== undefined && rowsPerPage !== undefined) {
    url += `page=${page}&page_size=${rowsPerPage}`
  }
  return `${path}?${url}`;
}
export const getMaps = ({ path, page, rowsPerPage }: { path: string, page?: number; rowsPerPage?: number }) => {
  let url = "";
  if (page !== undefined && rowsPerPage !== undefined) {
    url += `page=${page}&page_size=${rowsPerPage}`
  }
  return `${path}/?${url}`;
}
export const getCameraAnimations = ({ path, page, rowsPerPage, productId, productAnimationId, cameraAnimationId }: { path: string, page?: number; rowsPerPage?: number, productId: string, productAnimationId: string, cameraAnimationId: string }) => {
  let url = "";
  if (productId) {
    url += `product=${productId}&`
  }
  if (productAnimationId && path === "cameraAnimations") {
    url += `productAnimationId=${productAnimationId}&`
  }
  if (cameraAnimationId && path === "productAnimations") {
    url += `cameraAnimationId=${cameraAnimationId}&`
  }

  if (page !== undefined && rowsPerPage !== undefined) {
    url += `page=${page}&page_size=${rowsPerPage}`
  }
  return `${path}/?${url}`;
}
export const getFolder = ({ page, rowsPerPage }: { page?: number; rowsPerPage?: number }) => {
  let url = "";
  if (page !== undefined && rowsPerPage !== undefined) {
    url += `page=${page}&page_size=${rowsPerPage}`
  }
  return `folders/?${url}`;
}
export const getRenders = ({ page, rowsPerPage, folderId }: { page?: number; rowsPerPage?: number, folderId?: string }) => {
  let url = "";
  if (page !== undefined && rowsPerPage !== undefined) {
    url += `page=${page}&page_size=${rowsPerPage}`
  }
  return folderId ? `renders/?folder=${folderId}&${url}` : `renders/?${url}`;
}
export const getProjects = ({ page, rowsPerPage, folderId }: { page?: number; rowsPerPage?: number, folderId?: string }) => {
  let url = "";
  if (page !== undefined && rowsPerPage !== undefined) {
    url += `page=${page}&page_size=${rowsPerPage}`
  }
  return folderId ? `projects/?folder=${folderId}&${url}` : `projects/?${url}`;
}
export const deleteById = async (id: string, path: string, params?: any) => {
  return await deleteApiNew(`${path}${id}/`, params).then(() => {
    return { id, path }
  });

};
export const deleteByIds = async (ids: string[], path: string) => {
  const promises = ids.map((id) => deleteById(id, path));
  const responses = await Promise.all(promises);
  const data = await Promise.all(responses.map((res) => res));
  return data;
};